@import "upstairs/static_src/styles/_variables";
@import "upstairs/static_src/styles/_placeholders";
@import "upstairs/static_src/styles/_mixins";

.instagram {
  // border: 2px solid $brand;
  // border-bottom: 4px solid $brand;

  &__link {
    // border: 2px solid $brand;
    background: darken($brand, 10%);
  }

  &__block {
    overflow: hidden;
    height: 300px;

    &:hover {
      .instagram__cover {
        opacity: 1 !important;
      }
    }
  }

  &__image {
    height: 300px;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 0;
    font-family: "object-fit: cover;";
    opacity: 0.75;
    transition: opacity 0.25s;

    .instagram__link:hover & {
      opacity: 1;
    }
  }

  &__cover {
    color: $white;
    transition: background 0.25s;
    font-size: 0.85rem;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;

    div {
      z-index: 2;
    }

    .instagram__link:hover & {
      color: $white;
      background: $brand;
    }
  }

  &__icon {
    color: fade-out($white, 0.5);
    transition: color 0.25s;
    font-size: 1.2rem;
    top: 1rem;
    right: 1rem;

    .instagram__link:hover & {
      color: fade-out($black, 0.5);
      color: $white;
    }
  }
}
